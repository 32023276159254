<template>
    <div>
        <div class="login_container">
            <div class="login_left-side">
                <router-link to="/">
                    <div class="login_title">

                    </div>
                </router-link>
                <div class="login_left-content">
                </div>
            </div>

            <div class="login_right-side">
                <div class="account_text">
                    <div class="dont_have">
                        Don't have an account?
                        <router-link to="/register"><span class="sign_text"> Sign Up</span></router-link>
                    </div>
                </div>

                <div class="login_right-side-content">
                    <div class="welcome_title">
                        Welcome to <span class="name"> REZI LEADS</span>
                    </div>
                    <form @submit.prevent="login()">
                        <div class="input_item form-group">

                            <label>Email</label>
                            <b-form-input
                                    id="email"
                                    v-model="userForm.email"
                                    type="email"
                                    :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                                    trim
                            ></b-form-input>
                            <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                                <span v-if="!$v.userForm.email.required">Email field is required</span>
                                <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                            </div>
                        </div>
                        <div class="input_item form-group">
                            <label>Password</label>
                            <b-form-input
                                    id="password"
                                    :type="l_password"
                                    v-model="userForm.password"
                                    :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                                    trim
                            ></b-form-input>
                            <div class="eye_close" @click="showPassword()"></div>
                            <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                                <span v-if="!$v.userForm.password.required">Password field is required</span>
                                <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                            </div>
                            <div class="mt-1">
                                <span v-if="loginError" class="text-danger">{{loginError}}</span>
                            </div>
                        </div>
                        <div class="remember_forgot">
                            <div class="remember">
                                <input type="checkbox" id="check_remember">
                                <label for="check_remember">Remember me</label>

                            </div>
                            <div class="forget" @click="forgotPassword()">
                                Forgot your password?
                            </div>
                        </div>
                        <b-button variant="primary" class="login_btn" type="submit">Log In</b-button>

                    </form>
                    <router-link to="/">
                        <div class="back_website">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                <g id="arrow-left-circle" transform="translate(-1 -1)">
                                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="10" cy="10" r="10"
                                            transform="translate(2 2)" fill="none" stroke="#409edc"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_238" data-name="Path 238" d="M12,8,8,12l4,4" fill="none"
                                          stroke="#409edc" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"/>
                                    <line id="Line_128" data-name="Line 128" x1="8" transform="translate(8 12)"
                                          fill="none" stroke="#409edc" stroke-linecap="round" stroke-linejoin="round"
                                          stroke-width="2"/>
                                </g>
                            </svg>
                            Cancel and return to website
                        </div>
                    </router-link>
                </div>
            </div>

            <!--forgot modal start-->
            <b-modal ref="edit-modal" centered size="md" hide-header hide-footer>
                <div class="close_btn_content" @click="forgotPassword()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00457B" class="bi bi-x-lg"
                         viewBox="0 0 16 16">
                        <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/>
                    </svg>
                </div>
                <div class="modal_content">
                    <div class="modal_name">
                        Forgot password
                    </div>
                    <div class="forgot_text">
                        Enter your email address and we'll send you a link to reset your password.
                    </div>
                    <div class="modal_input">
                        <div class="input_item form-group">
                            <b-form-input
                                    id="forgot_email"
                                    placeholder="Email"
                                    v-model="forgot_email"
                                    type="email"
                                    trim
                            ></b-form-input>
                            <div class="mt-1">
                                <span v-if="forgotError" class="text-danger">{{forgotError}}</span>
                            </div>

                            <!--                            <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">-->
                            <!--                                <span v-if="!$v.userForm.email.required">Email field is required</span>-->
                            <!--                                <span v-if="!$v.userForm.email.email">Please provide valid email</span>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                    <div class="modal_btn">
                        <b-button class="send_btn" @click="sendForgotPassword()">Send</b-button>
                    </div>

                </div>

            </b-modal>
            <!--forgot modal end-->

        </div>
    </div>
</template>

<script>
    import {required, email, minLength} from "vuelidate/lib/validators";
    import {mapActions} from "vuex";

    export default {
        name: "Login",
        data() {
            return {
                userForm: {
                    email: "",
                    password: "",
                },
                forgot_email: '',
                isSubmitted: false,
                l_password: 'password',
                loginError: null,
                forgotError: null,
            }
        },
        validations: {
            userForm: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
            }
        },
        methods: {
            ...mapActions(['loginUser', 'sendForgot']),
            showPassword() {
                if (this.l_password === 'password') {
                    this.l_password = 'text'
                } else {
                    this.l_password = 'password'
                }
            },
            login() {
                this.isSubmitted = true;
                this.loginError = null
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                }else {
                    this.loginUser(this.userForm)
                        .then((res) => {
                            if(res.status == 200) {
                                this.$router.push('/dashboardLeads')
                            } else {
                                this.loginError = res.response.data.error
                            }
                        })
                }
            },
            forgotPassword() {
                this.forgot_email = ''
                this.$refs['edit-modal'].toggle('#edit-btn')
                this.forgotError = null
            },
            sendForgotPassword() {
                this.sendForgot(this.forgot_email)
                    .then((res) => {
                        if(res.status === 200) {
                            this.$refs['edit-modal'].toggle('#edit-btn')
                        } else {
                            this.forgotError = res.response.data.errors.email[0]
                        }
                    })

            }
        }
    }
</script>

<style scoped lang="scss">
    .form-group > label {
        font-weight: 600;
    }

    ::v-deep .modal-dialog {
        min-width: 550px;
        @media screen and (max-width: 600px) {
            min-width: 420px;
        }
        @media screen and (max-width: 425px) {
            min-width: unset;
        }

    }

    ::v-deep .modal-content {
        border: none;
    }

    .close_btn_content {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        top: 20px;
        right: 25px;
        cursor: pointer;
    }

    .login {
        &_container {
            width: 100%;
            height: 100vh;
            border: 1px solid lightgray;
            background: #fff;
            display: flex;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        &_left-side {
            width: 50%;
            padding: 55px 0 0 140px;
            display: flex;
            flex-direction: column;
            height: 100%;
            @media screen and (max-width: 1440px) {
                padding: 65px 65px 0 140px;
            }
            @media screen and (max-width: 1200px) {
                padding: 30px 40px 0 60px;
            }
            @media screen and (max-width: 768px) {
                padding: 30px 0 0 0;
                height: 65px;
                width: 100%;
            }
            @media screen and (max-width: 425px) {
                padding: 30px 10px 0;
                margin: 0 auto;

            }


            & a {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &_right-side {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (max-width: 1200px) {
                height: 83vh;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
            }

            & .account_text {
                width: 100%;
                padding: 50px 150px 10px;
                display: flex;
                justify-content: flex-end;
                @media screen and (max-width: 1440px) {
                    padding: 30px 90px;
                }
                @media screen and (max-width: 768px) {
                   padding: 10px 60px;
                    justify-content: center;
                }
                @media screen and (max-width: 425px) {
                    padding: 10px 25px;
                    justify-content: center;
                }

                & .dont_have {
                    color: $black;
                    font-weight: 500;
                    font-size: 18px;
                    @media screen and (max-width: 1440px) {
                        font-size: 16px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 14px;
                    }

                    & .sign_text {
                        color: $light_blue;
                        border-bottom: 1px solid $light_blue;

                    }
                }
            }

            &-content {
                max-width: 550px;
                width: 100%;
                height: 100%;
                display: flex;
                padding: 0 40px 40px 0;
                margin-right: 40px;
                justify-content: center;
                flex-direction: column;
                @media screen and (max-width: 1440px) {
                    padding: 0 20px 60px;
                }
                @media screen and (max-width: 1200px) {
                    max-width: 450px;
                }
                @media screen and (max-width: 1024px) {
                    max-width: 400px;
                    padding: 0 10px 0;
                }
                @media screen and (max-width: 768px) {
                    max-width: 380px;
                    padding: 0;
                    margin-right: 0;
                }
                @media screen and (max-width: 425px) {
                    max-width: none;
                    width: 100%;
                    height: auto;
                    padding: 0 25px;
                    margin-right: 0;
                    margin-top: 15px;
                }


                & .welcome_title {
                    font-size: 35px;
                    font-weight: 600;
                    color: $darkBlue;
                    padding: 20px 0;
                    @media screen and (max-width: 1440px) {
                        font-size: 32px;
                    }
                    @media screen and (max-width: 1200px) {
                        font-size: 30px;
                        padding: 15px 0;
                    }
                    @media screen and (max-width: 1024px) {
                        font-size: 26px;
                        padding: 10px 0;
                    }
                    @media screen and (max-width: 425px) {
                        font-size: 22px;
                        padding: 0 0 10px;
                    }

                    & .name {
                        color: $light_blue;
                    }
                }
                & .remember_forgot {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;
                    margin-bottom: 35px;
                    @media screen and (max-width: 425px) {
                        flex-direction: column;
                        margin-bottom: 25px;
                    }
                }

                & .input_item {
                    width: 100%;
                    margin-top: 20px;
                    position: relative;
                    @media screen and (max-width: 425px) {
                        margin-top: 15px;
                    }

                    & .eye_close {
                        position: absolute;
                        width: 30px;
                        height: 36px;
                        right: 15px;
                        top: 33px;
                        background-image: url("~@/assets/images/eye_close.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        cursor: pointer;
                    }

                    & label {
                        color: $darkBlue;
                        font-weight: 600;
                        font-size: 16px;
                    }

                    & input {
                        background: $blue_grey 0 0 no-repeat padding-box;
                        border: none;

                        &:focus {
                            box-shadow: none;
                            border: 1px solid $light_blue;
                            border-radius: 3px;
                        }
                    }
                }

                & .remember {
                    display: flex;
                    align-items: center;

                    & label {
                        margin-left: 15px !important;
                        color: $darkBlue;
                        font-size: 16px;
                        margin-bottom: 0;
                    }
                }

                & .forget {
                    color: $darkBlue;
                    font-size: 16px;
                    font-weight: 400;
                    cursor: pointer;
                    @media screen and (max-width: 425px) {
                        margin-top: 5px;
                    }
                }

                & .login_btn {
                    width: 100%;
                    height: 60px;
                    background: $light_blue;
                    border-radius: 10px;
                    color: $white;
                    font-weight: 600;
                    border: none;
                    @media screen and (max-width: 1440px) {
                        height: 50px;
                    }
                    @media screen and (max-width: 1024px) {
                        height: 45px;
                    }
                }

                & .back_website {
                    padding-top: 20px;
                    color: $main_blue;

                    & svg {
                        margin-right: 15px;
                    }
                }
            }
        }

        &_title {
            max-width: 220px;
            width: 100%;
            height: 30px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover !important;
            background: url("~@/assets/images/Logo.svg");
            @media screen and (max-width: 768px) {
                margin: 0 auto;
            }
        }

        &_left-content {
            padding: 30px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: url("~@/assets/images/login_img1.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @media screen and (max-width: 1440px) {
                padding: 110px 20px;
            }
            @media screen and (max-width: 1200px) {
                padding: 70px 20px;
            }
            @media screen and (max-width: 1024px) {
                padding: 0;
            }
            @media screen and (max-width: 768px) {
                display: none;
            }

        }
    }

    .modal_content {
        padding: 5px 15px 15px;
        border: none;

        & .modal_name {
            font-size: 25px;
            font-weight: 600;
            color: $grey;
            @media screen and (max-width: 425px) {
                font-size: 22px;
            }
        }

        & .forgot_text {
            padding: 10px 0 25px;
            font-size: 13px;
            color: $main_blue;
            @media screen and (max-width: 425px) {
                font-size: 12px;
            }
        }

        & .modal_input {
            padding-bottom: 20px;

            & .input_item {
                & input {
                    background: $blue_grey 0% 0% no-repeat padding-box;
                    border-radius: 3px;
                    width: 100%;
                    height: 45px;
                    border: none;
                }
            }
        }

        & .modal_btn {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            & .send_btn {
                background: $light_blue;
                border-radius: 10px;
                width: 110px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-weight: 600;
                border: none;
            }
        }
    }
</style>